export default {
  props: ['id', 'name', 'modelValue', 'required'],
  emits: ['update:modelValue'],
  setup(props, { emit })
  {
    const onChange = (e) =>
    {

      emit('update:modelValue', e.target.checked);
    };

    return {
      onChange,
    };
  },
  template: `
    <div class="form-checkbox">
      <input class="form-checkbox__input visually-hidden" @change="onChange" :id="id" :required="required" :name="name" type="checkbox" :checked="modelValue" />
      <label class="form-checkbox__label" :for="id"><slot></slot></label>
    </div>
  `,
};
