import FiltersPrograms from './FiltersPrograms';
import CardListPrograms from './CardListPrograms';

export default {
  props: ['modelValue'],
  components: {
    FiltersPrograms,
    CardListPrograms,
  },
  template: `
  <div class="catalog__columns">
    <FiltersPrograms v-model="modelValue" />
    <CardListPrograms />
  </div>
  `,
};
