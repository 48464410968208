export default {
  template: `
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
    <g clip-path="url(#clip0_690_747)">
      <path class="catalog-form__tooltip-circle" d="M10.0001 18.8334C14.6025 18.8334 18.3334 15.1025 18.3334 10.5001C18.3334 5.89771 14.6025 2.16675 10.0001 2.16675C5.39771 2.16675 1.66675 5.89771 1.66675 10.5001C1.66675 15.1025 5.39771 18.8334 10.0001 18.8334Z" stroke="#3E4B99" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
      <path class="catalog-form__tooltip-question" d="M7.57495 7.99999C7.77087 7.44304 8.15758 6.97341 8.66658 6.67426C9.17558 6.37512 9.77403 6.26577 10.3559 6.36558C10.9378 6.46539 11.4656 6.76792 11.8458 7.21959C12.2261 7.67126 12.4342 8.24292 12.4333 8.83332C12.4333 10.5 9.93328 11.3333 9.93328 11.3333" stroke="#3E4B99" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
      <path class="catalog-form__tooltip-question" d="M10 14.6667H10.0083" stroke="#3E4B99" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_690_747">
        <rect width="20" height="20" fill="white" transform="translate(0 0.5)"/>
      </clipPath>
    </defs>
  </svg>
  `
}


