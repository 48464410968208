import ArrowDownIcon from '../Icons/ArrowDownIcon';

const { ref, computed } = Vue;

export default {
  props: ['modelValue'],
  emits: ['update:modelValue', 'changeProgramsPerPage'],
  setup(props, { emit })
  {
    const selectedOption = ref(props.modelValue);
    const isDropdownOpen = ref(false);
    const options = [10, 20, 50, 100];

    const filteredOptions = computed(() => options.filter((option) => option !== selectedOption.value));

    const toggleDropdown = () =>
    {
      isDropdownOpen.value = !isDropdownOpen.value;
    };

    const selectOption = (option) =>
    {
      selectedOption.value = option;
      emit('update:modelValue', option);
      emit('changeProgramsPerPage');
      isDropdownOpen.value = false;
    };

    return {
      filteredOptions,
      selectedOption,
      isDropdownOpen,
      toggleDropdown,
      selectOption,
    };
  },
  components: {
    ArrowDownIcon,
  },
  template: `
    <div class="gl-custom-select">
      <div class="gl-custom-select__selected-option">
        <p class="gl-custom-select__label">Показывать</p>
        <div class="gl-custom-select__select" :class="{'gl-custom-select__select--open': isDropdownOpen}" @click="toggleDropdown">
          {{ selectedOption }}
          <ArrowDownIcon class="gl-custom-select__icon" />
        </div>
      </div>
      <ul v-if="isDropdownOpen" class="gl-custom-select__options">
        <li class="gl-custom-select__item" v-for="option in filteredOptions" :key="option" @click="selectOption(option)">
          {{ option }}
        </li>
      </ul>
    </div>
  `,
};
