import { StoreState, Ref } from '../types/types';
import FilterField from './UI/FilterField';
import SearchPrograms from './SearchPrograms';
import { debounce } from '../../../../../../libs/debounce';
import InputWithHelp from './UI/InputWithHelp';
import moment from 'moment';

const { ref, computed, reactive } = Vue;
const { useStore, mapState } = Vuex;

export default {
  emit: ['update:modelValue'],
  props: ['modelValue'],
  setup(props, { emit }) {
    const store: {state: StoreState, dispatch: any, commit: any, getters: any} = useStore();
    const storeRegions = computed(() => store.state.filters2.regions);
    const isShown: Ref<boolean> = ref(false);
    const formElement: Ref<null | HTMLFormElement> = ref(null);
    const storeSearch = computed(() => store.state.filters.search);

    const minDateValue = computed(() => {
      const date = new Date();

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so add 1 and pad with leading zeros
      const day = String(date.getDate()).padStart(2, '0'); // Pad with leading zeros if necessary

      return `${year}-${month}-${day}`;
    });

    const formData = reactive({
      region: '',
      actvityType: '',
      educationType: '',
      educationLevel: '',
    });

    const formData2 = reactive({
      search: '',
      region: '',
      date: '',
      activity: [],
      educationType: [],
      educationLevel: '',
    });

    const regions2 = computed(() => {
      const computedRegions = [];
      for(const [key, value] of Object.entries(storeRegions.value)) {
        computedRegions.push({
          id: key,
          name: value,
        });
      }
      return computedRegions;
    });

    const onCloseFiltersModalClick = () => {
      isShown.value = false;
      const scrollY = document.body.style.top;
      document.body.style.top = '';
      emit('update:modelValue', false);
      document.body.classList.remove('scroll-lock');
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    };

    const debouncedFetchNewPrograms = debounce(() => {
      store.dispatch('fetchNewPrograms');
    });

    const onResetButtonClick = () => {
      formData2.search = '';
      formData2.region = '';
      formData2.date = '';
      formData2.educationType = '';
      formData2.activity = [];
      const data = new FormData();
      if (store.state.lockedActivity.length) {
        data.append('Profile[]', store.state.lockedActivity);
      }
      store.commit('updateFormData', data);
      store.commit('updateFilters', formData2);
      store.commit('updateCurrentDate', moment());
      store.dispatch('fetchNewPrograms');
      formElement.value.reset();
    };

    const onFormChange = () => {
      if (formElement.value !== null) {
        const data = new FormData(formElement.value);
        data.append('Search', String(formData2.search.replaceAll(' ', '%20')));
        if(formData2.region.length)
        {
          const isRegionExist = regions2.value.filter((reg) => reg.name === formData2.region.trim()).length === 1;
          if (!isRegionExist) {
            return;
          }
          const regionUID = regions2.value.filter((region) => region.name === formData2.region)[0].id;

          data.set('Region[]', regionUID);
        } else {
          data.delete('Region[]');
        }
        store.commit('updateFilters', formData2);
        store.commit('updateFormData', data);
        store.dispatch('fetchNewPrograms');
      }
    };

    const onRegionChange = () => {
      if (formElement.value !== null) {
        const data = new FormData();
        if (formData2.region.length) {
          const regionUID = regions2.value.filter((region) => region.name === formData2.region)[0].id;
          data.set('Region[]', regionUID);
        } else {
          data.delete('Region[]');
        }
        // data.append('date', formData2.date);
        data.append('Search', formData2.search);
        formData2.educationType = '';
        formData2.activity = [];
        store.commit('updateFormData', data);
        store.commit('updateFilters', formData2);
        store.dispatch('fetchNewPrograms');
        formElement.value.reset();
      }
    };

    const onSearchChange = () => {
      if (formElement.value !== null) {
        const data = new FormData(formElement.value);
        data.append('Search', String(formData2.search.replaceAll(' ', '%20')));
        if(formData2.region.length)
        {
          const regionUID = regions2.value.filter((region) => region.name === formData2.region)[0].id;
          data.set('Region[]', regionUID);
        } else {
          data.delete('Region[]');
        }
        store.commit('updateFormData', data);
        store.commit('updateFilters', formData2);
        debouncedFetchNewPrograms();
      }
    };

    const getLockedActivity = computed(() => store.getters.getLockedActivity);

    const getLockedRegion = computed(() => {
      const computedRegions = [];
      for(const [key, value] of Object.entries(store.getters.getLockedRegion)) {
        computedRegions.push({
          id: key,
          name: value,
        });
      }
      return computedRegions;
    });

    const onDateChange = (e) => {
      store.commit('updateCurrentDate', moment(e.target.value));
    };

    return {
      formData2,
      formData,
      isShown,
      formElement,
      storeRegions,
      storeSearch,
      getLockedActivity,
      getLockedRegion,
      regions2,
      minDateValue,
      onCloseFiltersModalClick,
      onFormChange,
      onResetButtonClick,
      onRegionChange,
      onSearchChange,
      onDateChange,
    };
  },
  computed: {
    ...mapState(['filters2', 'lockedActivity', 'lockedRegion']),
  },
  components: {
    FilterField,
    InputWithHelp,
    SearchPrograms,
  },
  template: `
    <SearchPrograms v-model="formData2.search" v-model:filterModal="isShown" @changeSearchInput="onSearchChange"/>
    <section class="filters" :class="{'filters--open': isShown}">
      <form class="catalog-form" action="#" method="post" @change="onFormChange" @submit.prevent="() => false" ref="formElement">
        <div class="catalog-form__heading">
          <h2 class="catalog-form__title">Фильтры</h2>
          <button class="catalog-form__reset-button" type="reset" @click.prevent="onResetButtonClick">Сбросить</button>
          <button class="catalog-form__close-button" type="button" @click="onCloseFiltersModalClick">
            <span class="visually-hidden">Закрыть модальное окно с фильтрами</span>
          </button>
        </div>
        <div class="catalog-form__filters">
          <InputWithHelp v-if="lockedRegion.length === 0" label="Выберите регион" :options="regions2" v-model="formData2.region" @changeRegion="onRegionChange"/>
          <InputWithHelp v-else label="Выберите регион" :options="getLockedRegion" v-model="formData2.region" :locked="true"/>
          <div v-if="false" class="catalog-form__fieldset catalog-form__fieldset--date">
            <p class="catalog-form__legend">Укажите дату, когда вы готовы начать обучение</p>
            <input class="catalog-form__date" type="date" name="date" :min="minDateValue" max="2030-12-31" v-model="formData2.date" @change="onDateChange">
          </div>
          <FilterField
            v-if="lockedActivity.length === 0"
            :data="filters2.activities"
            label="Сферы деятельности"
            name="Profile[]"
            v-model="formData2.activity"
          />
          <FilterField
            v-else
            :data="getLockedActivity"
            label="Сферы деятельности"
            name="Profile[]"
            :locked="true"
            v-model="formData2.activity"
          />
          <FilterField
            :data="filters2.educationLevel"
            label="Уровень образования"
            name="MinEduLevel[]"
            v-model="formData2.educationLevel"
            tooltip="true"
          >
            <p class="catalog-form__tooltip-text">Проверяйте требования к образованию при выборе программы. Если Ваш уровень образования ниже указанного в программе, заявку могут отклонить</p>
          </FilterField>
          <FilterField
            :data="filters2.educationType"
            type="radio"
            label="Формат обучения"
            name="EduForm[]"
            v-model="formData2.educationType"
          />
        </div>
        <div class="catalog-form__reset">
          <button class="catalog-form__reset-button catalog-form__reset-button--mobile" type="reset" @click.prevent="onResetButtonClick">Сбросить</button>
        </div>
      </form>
    </section>
   `,
};

