import CatalogPrograms from './components/CatalogPrograms.js';
import Closed from './components/Closed.js';
import store from './store/index.js';
import SuccessModal from './components/UI/SuccessModal';
import ErrorModal from './components/UI/ErrorModal.js';

window.addEventListener('DOMContentLoaded', () => {
  window.addEventListener('load', () => {
    const { createApp, computed } = Vue;
    const { useStore } = Vuex;

    // const app2 = createApp({
    //   components: {
    //     Closed,
    //     SuccessModal,
    //     ErrorModal,
    //   },
    //   setup() {
    //     const isLoading = computed(() => store.state.isLoading);
    //     const isSuccess = computed(() => store.state.isSuccess);
    //     const isError = computed(() => store.state.isError);

    //     return {
    //       isLoading,
    //       isSuccess,
    //       isError,
    //     };
    //   },
    //   template: `
    //       <div class="wrapper program-picker">
    //         <Closed />
    //       </div>
    //       <div class="preloader" v-show="isLoading"><div></div></div>
    //       <SuccessModal v-show="isSuccess"/>
    //       <ErrorModal v-show="isError" />
    //     `,
    // });

    // app2.use(store);
    // app2.mount('#programm');

    let regions;

    const fetchRegions = async () => {
      const response = await fetch('/sync/getTrud.php?page=1&limit=10');
      const data = await response.json();
      return data.filter_data.Region;
    };

    fetchRegions().then((data) => {
      regions = data;

      const app = createApp({
        components: {
          CatalogPrograms,
        },
        setup() {
          const store = useStore();
          const isLoading = computed(() => store.state.isLoading);
          store.commit('updateFilters2Region', regions);

          if (typeof (lockedProperties) !== 'undefined') {
            store.commit('updateLockedRegion', lockedProperties);
          }
          store.dispatch('fetchPrograms');

          const windowLocation = window.location.pathname.includes('/catalog-dpo/') ? 'Каталог программ' : 'Подобрать программу';

          return {
            isLoading,
            windowLocation,
          };
        },
        template: `
          <div class="wrapper program-picker">
            <h2 class="program-picker__heading">{{windowLocation}}</h2>
            <CatalogPrograms />
          </div>
          <div class="preloader" v-show="isLoading"><div></div></div>
        `,
      });

      app.use(store);
      app.mount('#programm');
    });
  });
});
