import { Ref } from '../../types/types';

const { ref, computed, onUpdated } = Vue;
const { useStore } = Vuex;

export default {
  emits: ['update:modelValue', 'regionChange'],
  props: ['label', 'options', 'modelValue', 'error', 'data'],
  setup(props, { emit })
  {
    const isShown: Ref<boolean> = ref(false);
    const error: Ref<boolean> = ref(false);
    const selectedValue = ref('');
    const regionUID = ref('');
    const selectedUID = ref('');
    const store = useStore();
    const lockedRegion = computed(() => store.state.lockedRegion);
    const filteredList = computed(() =>
      props.options.filter((region) => region.Region.toLowerCase().includes(props.modelValue.toLowerCase().trim())));

    const onInput = (e: Event & { target: HTMLInputElement }) =>
    {
      isShown.value = true;
      selectedValue.value = e.target.value;
      emit('update:modelValue', e.target.value);
      selectedUID.value = '';
    };

    const onOptionClick = (option: { name: string }, uid) =>
    {
      selectedValue.value = option.Region;
      selectedUID.value = uid;
      isShown.value = false;
      emit('update:modelValue', selectedValue.value);
      const isLinkExist = props.data.Ar_RvR_Link_Region[uid];
      if(!isLinkExist)
      {
        emit('regionChange', uid);
        store.state.linkLoading = true;
      }
      document.body.removeEventListener('click', handleClickOutside);
    };

    const toggleList = () =>
    {
      if(!isShown.value)
      {
        isShown.value = true;
        document.body.addEventListener('click', handleClickOutside);
      }
      else
      {
        isShown.value = false;
        document.body.removeEventListener('click', handleClickOutside);
      }
    };

    const handleClickOutside = (e: Event) =>
    {
      const target = e.target as HTMLInputElement;
      if(!target.closest('.input-help'))
      {
        isShown.value = false;
        selectedValue.value = '';
        emit('update:modelValue', selectedValue.value);
      }
    };

    onUpdated(() =>
    {
      regionUID.value = Object.keys(store.state.filters2.regions)[0];
      const isLinkExist = props.data.Ar_RvR_Link_Region[regionUID.value];
      if(lockedRegion.value && !isLinkExist)
      {
        return store.dispatch('fetchfeedbackLink', {
          program: props.data.UID,
          region: regionUID.value,
        });
      }
      if(lockedRegion.value)
      {
        emit('update:modelValue', lockedRegion.value);
      }
    });

    return {
      isShown,
      error,
      selectedValue,
      regionUID,
      selectedUID,
      lockedRegion,
      filteredList,
      onInput,
      onOptionClick,
      toggleList,
    };
  },
  template: `
  <div v-if="options.length > 1 && lockedRegion.length === 0" class="input-help" :class="{'input--error': error, 'input-help--open': isShown}">
    <label class="input-help__label" for="region-modal">Регион</label>
    <input
      class="input-help__input input-help__input--region"
      name="UID_Region"
      id="region-modal"
      type="text"
      autocomplete="off"
      :placeholder="label"
      @input="onInput"
      @click="toggleList"
      :value="modelValue"
      :data-UID="selectedUID"
      required
    />
    <ul v-show="isShown && filteredList.length > 0" class="input-help__list">
      <li v-for="option in filteredList" class="input-help__item" @click="onOptionClick(option, option.UID_Region)" :data-region-uid="option.UID_Region" :key="option.UID_Region">{{option.Region}}</li>
    </ul>
    <ul v-show="isShown && filteredList.length === 0" class="input-help__list">
      <li class="input-help__item">Регион не найден: проверьте корректность написания или выберите другой регион. </li>
    </ul>
  </div>
  <div v-else-if="lockedRegion.length > 1">
    <label class="input-help__label" for="region-modal">Регион</label>
    <input
      class="input-help__input"
      name="UID_Region"
      id="region-modal"
      type="text"
      readonly
      :value="lockedRegion"
      :data-UID="regionUID"
      required
    />
  </div>
  <div v-else>
    <label class="input-help__label" for="region-modal">Регион</label>
    <input
      class="input-help__input"
      name="UID_Region"
      id="region-modal"
      type="text"
      readonly
      :value="modelValue"
      :data-UID="options[0].UID_Region"
    />
  </div>
  `,
};
