import TooltipIcon from '../Icons/TooltipIcon';

const { ref, computed, onUpdated } = Vue;

export default {
  emits: ['update:modelValue'],
  props: ['data', 'label', 'name', 'modelValue', 'type', 'filteredData', 'tooltip', 'locked'],
  setup(props, { emit }) {
    const tooltipShown: {value: boolean} = ref(false);
    const options = computed(() => props.data ? Object.entries(props.data) : []);
    const selectedValue = ref([]);

    const onChange = () => {
      emit('update:modelValue', selectedValue.value);
    };

    const isDisabled = (name) => {
      if (props.filteredData) {
        return props.filteredData.filter((filter) => filter.name === name).length === 0;
      }
    };

    const isChecked = (code) =>
      props.modelValue.includes(code);

    return {
      onChange,
      isChecked,
      isDisabled,
      selectedValue,
      options,
      tooltipShown,
    };
  },
  components: {
    TooltipIcon,
  },
  template: `
    <div class="catalog-form__fieldset catalog-form__fieldset--open">
      <p v-if="!tooltip" class="catalog-form__legend">{{label}}</p>
      <div class="catalog-form__tooltip" v-else>
        <p class="catalog-form__legend">{{label}}</p>
        <div
          class="catalog-form__tooltip-element"
          :class="{'catalog-form__tooltip-element--open': tooltipShown}"
        >
          <TooltipIcon class="catalog-form__tooltip-icon" />
          <div class="catalog-form__tooltip-content">
            <slot></slot>
          </div>
        </div>
      </div>
      <ul class="catalog-form__list">
        <li class="catalog-form__item" v-if="!locked" v-for="filter in options" :key="filter[0]">
          <div class="catalog-form__variant">
            <input
              class="visually-hidden"
              :class="type === 'radio' ? 'catalog-form__radio' : 'catalog-form__checkbox'"
              :type="type === 'radio' ? 'radio' : 'checkbox'"
              :value="filter[0]"
              :checked="modelValue.includes(filter[0])"
              :name="name"
              :id="name + filter[0]"
              @change="onChange()"
            >
            <label class="catalog-form__label" :class="type === 'radio' ? 'catalog-form__label--radio' : ''" :for="name+filter[0]" >{{filter[1]}}</label>
          </div>
        </li>
        <li class="catalog-form__item" v-else v-for="filter in options" :key="filter[0] + 1">
          <div class="catalog-form__variant">
            <input
              class="visually-hidden catalog-form__checkbox"
              type="checkbox"
              :value="filter[0]"
              :checked="true"
              :name="name"
              :id="name + filter[0]"
            >
            <label class="catalog-form__label catalog-form__label--locked">{{filter[1]}}</label>
          </div>
        </li>
      </ul>
    </div>
  `,
};
