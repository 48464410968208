const { ref, onMounted } = Vue;

export default {
  setup()
  {
    const timer = ref(10);

    const countdown = () =>
    {
      setInterval(() =>
      {
        if(timer.value > 0)
        {
          timer.value--;
        }
      }, 1000);
    };

    onMounted(countdown);

    return {
      timer,
    };
  },
  template: `
    <div class="progres">
      <div class="progres__container">
        <div class="progres__bar"></div>
      </div>
      <p class="progres__text">Осталось {{ timer }} сек.</p>
      <p class="progres__text">Нам нужно немного времени: проверяем, есть ли программа в регионе</p>
    </div>
  `,
};
