export default {
  props: [
    'typeButton',
    'disabled'
  ],
  template: `
  <button class="trud-button"
    type="button"
    :disabled="disabled"
    :class="{ 'trud-button--primary': typeButton === 'primary',
              'trud-button--secondary': typeButton === 'secondary',
              'trud-button--link': typeButton === 'link' }"
  >
    <slot></slot>
  </button>
  `,
};
