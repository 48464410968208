import FilterIcon from './Icons/FilterIcon';
import { debounce } from '../libs/debounce';

export default {
  emit: ['update:modelValue', 'update:filterModal', 'changeSearchInput'],
  props: ['modelValue', 'filterModal'],
  setup(props, { emit })
  {
    const onSearchInput: (e: Event & {target: HTMLInputElement}) => void = (e) =>
    {
      const target = e.target;
      emit('update:modelValue', target.value);
      emit('changeSearchInput');
    };


    // const debouncedFetchNewPrograms = debounce(() => store.dispatch('fetchNewPrograms'));

    const onClearSearchButtonClick: () => void = () =>
    {
      emit('update:modelValue', '');
      emit('changeSearchInput');
    };

    const onOpenFilterModalClick = () =>
    {
      document.body.style.top = `-${window.scrollY}px`;
      document.querySelector('.filters').style.top = `${window.scrollY}px`;
      document.body.classList.add('scroll-lock');
      return emit('update:filterModal', true);
    };

    const onEnterClick = () =>
    {
      document.activeElement.blur();
    };

    return {
      onSearchInput,
      onClearSearchButtonClick,
      onOpenFilterModalClick,
      onEnterClick,
    };
  },
  components: {
    FilterIcon,
  },
  template: `
    <div class="search">
      <input class="search__input" type="text" @keyup.enter="onEnterClick" placeholder="Искать программу..." :value="modelValue" @input="onSearchInput"/>
      <button class="search__clear-button" @click="onClearSearchButtonClick">
        <span class="visually-hidden">Очистить поиск</span>
      </button>
      <button class="search__filter-button" @click="onOpenFilterModalClick">
        <FilterIcon />
        <span class="visually-hidden">Открыть попап с фильтрами</span>
      </button>
    </div>
  `,
};
