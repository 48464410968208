import ProgramCard from './ProgramCard';
import PaginationPrograms from './PaginationPrograms';
import CustomSelect from './UI/CustomSelect';
import FormField from './UI/FormField';
import FormCheckbox from './UI/FormCheckbox';
import Button from './UI/Button';
import { Ref } from '../types/types';
import InputWithHelpModal from './UI/InputWithHelpModal';
import moment from 'moment';
import ProgresBar from './ProgresBar';
import LinkErrorModal from './UI/LinkErrorModal';
import LinkEmptyModal from './UI/LinkEmptyModal';

const { useStore, mapState } = Vuex;
const { ref, computed, reactive, onMounted } = Vue;

export default {
  setup() {
    const store = useStore();
    const storePrograms = computed(() => store.state.programs);
    const storeCount = computed(() => store.state.foundPrograms);
    const places: Ref<string[]> = ref([]);
    const dates = ref([]);
    const programData = ref({
      Ar_Regions: [{
        name: 'test',
      }],
      Ar_RvR_Link_Region: [],
    });
    const isModalShown: Ref<boolean> = ref(false);
    const isDateModalShown: Ref<boolean> = ref(false);
    const programsPerPage: Ref<number> = ref(10);
    const feedbackRegion = ref('');
    const feedbackRegionUID = ref('');
    const feedbackForm = ref(null);
    const userData = sessionStorage.user ? JSON.parse(sessionStorage.user) : '';
    const formData = reactive({
      firstName: userData ? userData.name : '',
      secondName: userData ? userData.secondName : '',
      email: userData ? userData.email : '',
      phone: userData ? userData.phone : '',
      agree: false,
      agreeMarketing: false,
    });

    const showRegionModal = (data: {Region: string, UID_Region: string}[]) => {
      places.value = data;
      isModalShown.value = true;
      document.body.style.top = `-${window.scrollY}px`;
      document.body.classList.add('scroll-lock');
    };

    const showFeedbackModal = (data) => {
      resetFormData();
      formData.agree = false;
      formData.agreeMarketing = false;
      feedbackRegion.value = '';
      store.commit('resetFormStep');
      programData.value = data;
      store.commit('toggleFeedbackForm');
      document.body.style.top = `-${window.scrollY}px`;
      document.body.classList.add('scroll-lock');
      if (programData.value.Ar_Regions.length === 1) {
        feedbackRegion.value = programData.value.Ar_Regions[0].Region;
      }
    };

    const showDateModal = (data) => {
      dates.value = data;
      isDateModalShown.value = true;
      document.body.style.top = `-${window.scrollY}px`;
      document.body.classList.add('scroll-lock');
    };

    const onModalClick = (e) => {
      if (!e.target.closest('.gl-modal__container')) {
        isModalShown.value = false;
        isDateModalShown.value = false;
        store.state.isLoadingLinkError = false;
        store.state.isFeedbackModalShown = false;
        store.state.isLoadingLinkEmptyError = false;
        store.commit('resetError');
        const scrollY = document.body.style.top;
        document.body.style.top = '';
        document.body.classList.remove('scroll-lock');
        window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
      }
    };

    const onModalButtonClick = (e) => {
      isModalShown.value = false;
      isDateModalShown.value = false;
      store.state.isFeedbackModalShown = false;
      store.commit('resetError');
      const scrollY = document.body.style.top;
      document.body.style.top = '';
      document.body.classList.remove('scroll-lock');
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
      resetFormData();
    };

    const storeSearch = computed(() => store.state.filters.search);
    const isControlShown = computed(() => store.getters.isControlShown);
    const isLinkLoading = computed(() => store.state.linkLoading);

    const onChangeProgramsCount = () => {
      store.commit('updateProgramsCount', programsPerPage.value);
    };

    const onSubmitFeddbackForm = async (e) => {
      const data = new FormData(feedbackForm.value);
      data.set('UID_KatalogDPO', programData.value.UID);
      data.set('UID_Region', document.querySelector('.input-help__input[name="UID_Region"]').dataset.uid);
      feedbackRegionUID.value = document.querySelector('.input-help__input[name="UID_Region"]').dataset.uid;
      data.delete('agree');
      const program = programData.value.Title;
      const level = programData.value.EduForm;
      const region = feedbackRegion.value;
      let link = '';
      if (!Object.keys(programData.value.Ar_RvR_Link_Region).length) {
        link = store.state.links;
      } else {
        link = programData.value.Ar_RvR_Link_Region[document.querySelector('.input-help__input[name="UID_Region"]').dataset.uid];
      }
      if (link.length === 0) {
        store.commit('toggleFeedbackForm');
        store.commit('toggleError');
        store.commit('updateErrorMessage', 'Что-то пошло не так попробуйте еще раз');
        localStorage.clear();
        resetFormData();
        return;
      }
      if (region.trim().length > 0) {
        store.dispatch('sendFeedbackForm', {
          data, level, region, program, link,
        });
      }
    };

    const resetFormData = () => {
      formData.firstName = '';
      formData.secondName = '';
      formData.email = '';
      formData.phone = '';
      formData.agree = false;
      formData.agreeMarketing = false;
      feedbackRegion.value = '';
      feedbackRegionUID.value = '';
    };

    const onSubmitApplicationClick = () => {
      const link = document.createElement('a');
      link.target = '_blank';
      link.onclick = ym(84290710, 'reachGoal', 'podverdit');
      link.href = store.state.links;
      link.click();
    };

    const onRegionChange = (region) => {
      feedbackRegionUID.value = region;
      store.dispatch('fetchfeedbackLink', {
        program: programData.value.UID,
        region,
      });
    };

    const onModalChangeRegion = () => {
      feedbackRegion.value = '';
      feedbackRegionUID.value = '';
    };

    const removeRegion = (uid, region) => {
      const data = [...store.state.programs];
      const index = data.findIndex((program) => program.UID === uid);
      data[index].Ar_Regions = [
        ...data[index].Ar_Regions.slice(0, index),
        ...data[index].Ar_Regions.slice(index + 1)
      ];
      delete data[index].Ar_Regions[region];
      feedbackRegion.value = '';
      feedbackRegionUID.value = '';
    };

    const getRecruitmentText = (startDate, endDate) => `${moment(startDate).format('DD.MM.YYYY')} - ${moment(endDate).format('DD.MM.YYYY')}`;

    const onReloadPage = () => {
      const saveUserData = {
        name: formData.firstName,
        secondName: formData.secondName,
        email: formData.email,
        phone: formData.phone,
      };
      sessionStorage.setItem('user', JSON.stringify(saveUserData));
    };

    onMounted(() => window.addEventListener('beforeunload', onReloadPage));

    const onEnterClick = (e) => {
      e.preventDefault();
    };


    const isButtonDisabled = computed(() => {
      const emailRegExp = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
      const phoneRegExp = /X/g;
      return (formData.firstName.length > 0 &&
        formData.secondName.length > 0 &&
        emailRegExp.test(formData.email) &&
        !phoneRegExp.test(formData.phone) &&
        formData.agree &&
        formData.agreeMarketing &&
        !isLinkLoading.value &&
        feedbackRegion.value.trim().length > 0);
    });


    return {
      programsPerPage,
      places,
      isModalShown,
      storePrograms,
      storeCount,
      storeSearch,
      isControlShown,
      programData,
      formData,
      feedbackRegion,
      feedbackForm,
      feedbackRegionUID,
      dates,
      isDateModalShown,
      isLinkLoading,
      isButtonDisabled,
      showFeedbackModal,
      onChangeProgramsCount,
      onSubmitFeddbackForm,
      showRegionModal,
      onModalClick,
      onModalButtonClick,
      onSubmitApplicationClick,
      showDateModal,
      getRecruitmentText,
      onRegionChange,
      onModalChangeRegion,
      removeRegion,
      onEnterClick,
    };
  },
  computed: {
    ...mapState([
      'isLoading',
      'formStep',
      'isFeedbackModalShown',
      'isError',
      'isLoadingLinkError',
      'isLoadingLinkEmptyError',
      'errorMessage'
    ]),
  },
  components: {
    ProgramCard,
    PaginationPrograms,
    CustomSelect,
    FormField,
    FormCheckbox,
    Button,
    InputWithHelpModal,
    ProgresBar,
    LinkErrorModal,
    LinkEmptyModal,
  },
  template: `
    <section class="programs">
      <div class="programs__control" v-show="isControlShown">
        <p class="programs__find">Найдено программ: {{storeCount}}</p>
        <CustomSelect v-model="programsPerPage" @changeProgramsPerPage="onChangeProgramsCount"/>
      </div>
      <ul class="programs__list" v-if="storePrograms.length > 0">
        <ProgramCard v-for="program in storePrograms" :data="program" :key="program.id" @updateRegionModal="showRegionModal" @updateDateModal="showDateModal" @updateFeedbackModal="showFeedbackModal"/>
      </ul>
      <p v-else-if="!isLoading" class="programs__empty">По выбранным параметрам ничего не найдено.<br> Попробуйте изменить фильтры.</p>
      <PaginationPrograms :programsPerPage="programsPerPage" />
    </section>
    <Teleport to="body">
      <div v-show="isModalShown" class="gl-modal" @click="onModalClick">
        <div class="gl-modal__container">
          <button class="gl-modal__close-button" @click="onModalButtonClick">
            <span class="visually-hidden">Закрыть</span>
          </button>
          <ul class="gl-modal__list">
            <li class="gl-modal__item" v-for="place in places">
              {{place.Region}}
            </li>
          </ul>
        </div>
      </div>
      <div v-show="isDateModalShown" class="gl-modal" @click="onModalClick">
        <div class="gl-modal__container">
          <button class="gl-modal__close-button" @click="onModalButtonClick">
            <span class="visually-hidden">Закрыть</span>
          </button>
          <ul class="gl-modal__list">
            <li class="gl-modal__item" v-for="date in dates">
              {{getRecruitmentText(date.RegistrationStart, date.RegistrationEnd)}}
            </li>
          </ul>
        </div>
      </div>
      <div v-show="isError" class="gl-modal gl-modal--error" @click="onModalClick">
        <div class="gl-modal__container">
          {{errorMessage}}
        </div>
      </div>
      <LinkErrorModal v-show="isLoadingLinkError" :data="{programData, feedbackRegionUID}" @click="onModalClick" @changeRegion="onModalChangeRegion"/>
      <LinkEmptyModal v-show="isLoadingLinkEmptyError" :data="{programData, feedbackRegionUID}" @click="onModalClick" @changeEmptyRegion="removeRegion"/>
      <div v-show="isFeedbackModalShown" class="gl-modal gl-modal--form" @click="onModalClick">
        <div class="gl-modal__container" v-show="formStep === 1">
          <button class="gl-modal__close-button" @click="onModalButtonClick">
            <span class="visually-hidden">Закрыть</span>
          </button>
          <div class="gl-modal__step">
            <div class="gl-modal__heading">
              <h3 class="gl-modal__title">Оставить заявку</h3>
              <p class="gl-modal__subtitle">Шаг 1 из 2</p>
            </div>
            <form class="gl-modal__form" method="post" ref="feedbackForm" @keyup="onEnterClick" @submit.prevent="onSubmitFeddbackForm">
              <input type="hidden" name="NotData" value="false" />
              <input type="hidden" name="UID_UserOU" value="" />
              <input type="hidden" name="MiddleName" value="" />
              <FormField
                class="gl-modal__wide"
                isReadonly="true"
                :required="true"
                label="Выбранная программа"
                id="programName"
                name="UID_KatalogDPO"
                type="text"
                v-model="programData.Title"
              />
              <FormField
                label="Имя"
                id="name"
                name="FirstName"
                :required="true"
                type="text"
                placeholder="Иван"
                v-model="formData.firstName"
                :isReadonly="isLinkLoading"
              />
              <FormField
                label="Фамилия"
                id="secondName"
                name="LastName"
                :required="true"
                type="text"
                placeholder="Иванов"
                v-model="formData.secondName"
                :isReadonly="isLinkLoading"
              />
              <FormField
                label="Email"
                id="email"
                name="Email"
                type="email"
                :required="true"
                placeholder="example@mail.ru"
                v-model="formData.email"
                :isReadonly="isLinkLoading"
              />
              <FormField
                label="Телефон"
                id="phoneNumber"
                name="Phone"
                :required="true"
                type="tel"
                placeholder="+7 (XXX) XXX-XX-XX"
                v-model="formData.phone"
                :isReadonly="isLinkLoading"
              />
              <InputWithHelpModal
                class="gl-modal__wide gl-modal__input-help"
                :required="true"
                v-model="feedbackRegion"
                label="Выберите регион"
                :options="programData.Ar_Regions"
                :data="programData"
                @regionChange="onRegionChange"
              />
              <ProgresBar v-if="isLinkLoading" />

              <div class="form-checkbox__wrapper"> 
                <FormCheckbox class="gl-modal__wide" id="agree" name="agree" :required="true" v-model="formData.agree" >
                <div class="form-checkbox__text">Я даю свое <a class="red" href="https://www.ranepa.ru/images/docs/svedeniya_ooo/Politika_personal_dannih_02.02.2017.pdf" target="_blank">согласие</a> на обработку моих персональных данных<br>
                <a class="red" href="https://www.ranepa.ru/local/templates/ranepa_2024/docs/privacy_policy.pdf" target="_blank">Политика </a>РАНХиГС в отношении обработки персональных данных.
                </div>
                </FormCheckbox>

                 <FormCheckbox class="gl-modal__wide" id="agreeMarketing" name="agreeMarketing" :required="true" v-model="formData.agreeMarketing" >
                   
                <div class="form-checkbox__text">Я даю свое <a class="red" href="/upload/trud/soglasiya/Согласие на рекламную рассылку.pdf" target="_blank">согласие</a> на рекламно-информационную рассылку
                </div>
              </FormCheckbox>
              </div>
              <Button
                :disabled="!isButtonDisabled"
                class="gl-modal__wide gl-modal__button"
                type="submit"
                typeButton="primary"
                onclick="ym(84290710, 'reachGoal', 'submit_an_application'); return true;"
              >
              Отправить
              </Button>
            </form>
          </div>
        </div>
        <div class="gl-modal__container" v-show="formStep === 2">
          <button class="gl-modal__close-button" @click="onModalButtonClick">
            <span class="visually-hidden">Закрыть</span>
          </button>
          <div class="gl-modal__step gl-modal__step--second">
            <div class="gl-modal__heading">
              <h3 class="gl-modal__title gl-modal__title--second-step">Спасибо, ваша заявка отправлена!</h3>
              <p class="gl-modal__subtitle">Шаг 2 из 2</p>
              <div class="gl-modal__subheading">
                <p class="gl-modal__text">Осталось подтвердить заявку на портале «Работа России»</p>
                <p class="gl-modal__text gl-modal__text--bold">Без подтверждения заявки участие невозможно</p>
              </div>
            </div>
            <Button class="gl-modal__button" typeButton="primary" @click="onSubmitApplicationClick">Подтвердить заявку</Button>
            <div class="gl-modal__instuction">
              <ol class="gl-modal__list">
                <li class="gl-modal__item">После нажатия кнопки «Подтвердить заявку» вы автоматически перейдете на страницу выбранной программы на сайте «Работа России»</li>
                <li class="gl-modal__item">Там вы увидите кнопку «Подать заявку». Нажмите ее. На открывшейся странице нажмите кнопку «Войти через портал «Госуслуги»</li>
                <li class="gl-modal__item">После авторизации через «Госуслуги» под своей учетной записью еще раз нажмите кнопку «Подать заявку»</li>
                <li class="gl-modal__item">В открывшейся анкете заполните все поля, отмеченные галочкой, и нажмите кнопку «Отправить заявку»</li>
                <li class="gl-modal__item">Поздравляем! Вы успешно подтвердили заявку на бесплатное обучение.</li>
              </ol>
            </div>
              <p class="gl-modal__documents">
                Подробная инструкция <a class="gl-modal__link" href="https://www.ranepa.ru/images/trud/files/trud_PBP3.pdf" target="_blank">«как подтвердить заявку»</a> и <a class="gl-modal__link" href="https://www.ranepa.ru/images/trud/files/trud_docs.pdf" target="_blank">список документов</a><br /> для каждой категории отправлены вам на почту или смотрите по ссылкам выше.
              </p>
          </div>
        </div>
      </div>
    </Teleport>
  `,
};
