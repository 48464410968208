const { ref, onMounted } = Vue;

export default {
  emits: ['update:modelValue'],
  setup(props, { emit })
  {
    const inputRef = ref(null);
    const onInput = (e) =>
    {
      emit('update:modelValue', e.target.value);
    };

    const onChange = (e) =>
    {
      emit('update:modelValue', e.target.value);
    };

    onMounted(() =>
    {
      if(inputRef.value && props.type === 'tel')
      {
        const maskOptions = {
          mask: '+7 (999) 999-99-99',
          placeholder: '+7 (XXX) XXX-XX-XX',
          inputmode: 'tel',
        };

        Inputmask(maskOptions).mask(inputRef.value);
      }
    });

    return {
      inputRef,
      onInput,
      onChange,
    };
  },
  props: ['label', 'id', 'name', 'type', 'placeholder', 'modelValue', 'isReadonly', 'required'],
  template: `
  <div class="form-input">
    <label class="form-input__label" :for="id">{{ label }}</label>
    <input
      ref="inputRef"
      class="form-input__input"
      :required="required"
      :id="id"
      :name="name"
      :type="type"
      :placeholder="placeholder"
      :readonly="isReadonly"
      :value="modelValue"
      @input="onInput"
      @change="onChange"
    />
  </div>
  `,
};
