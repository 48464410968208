import { Ref } from '../../types/types';

const { ref, computed } = Vue;

type OptionType = {
  ID: string;
  NAME: string;
  CODE: string;
  IBLOCK_SECTION_ID: string;
}

type InputWithHelpProps = {
  label: string,
  options: OptionType[],
  modelValue: string,
  error: boolean
}

export default {
  emits: ['update:modelValue', 'changeRegion'],
  props: ['label', 'options', 'modelValue', 'error', 'locked'],
  setup(props: InputWithHelpProps, { emit })
  {
    const isShown: Ref<boolean> = ref(false);
    const error: Ref<boolean> = ref(false);
    const selectedValue = ref('');

    const filteredList = computed(() =>
      props.options.filter((region) => region.name.toLowerCase().includes(selectedValue.value.toLowerCase().trim())));

    const onInput = (e: Event & { target: HTMLInputElement }) =>
    {
      isShown.value = true;
      let inputValue = e.target.value;
      selectedValue.value = inputValue.trim();
      if(inputValue.length === 0)
      {
        inputValue = '';
      }
      emit('update:modelValue', inputValue);
    };

    const onOptionClick = (event, option: { name: string }) =>
    {
      event.stopPropagation();
      selectedValue.value = option.name;
      emit('update:modelValue', selectedValue.value);
      emit('changeRegion');
      isShown.value = false;
      selectedValue.value = '';
      document.body.removeEventListener('click', handleClickOutside);
    };

    const toggleList = () =>
    {
      if(!isShown.value)
      {
        isShown.value = true;
        selectedValue.value = '';
        emit('update:modelValue', '');
        document.body.addEventListener('click', handleClickOutside);
      }
      else
      {
        document.body.removeEventListener('click', handleClickOutside);
        isShown.value = false;
        emit('changeRegion');
      }
    };

    const onFocusOut = () =>
    {
      setTimeout(() =>
      {
        if(props.modelValue.length > 1)
        {
          error.value = !(props.options.filter((region) => region.name.toLowerCase() === props.modelValue.toLowerCase()).length > 0);
          setTimeout(() =>
          {
            error.value = false;
          }, 2000);
        }
      }, 100);
    };

    const handleClickOutside = (e: Event) =>
    {
      const target = e.target as HTMLInputElement;
      if(!target.closest('.input-help'))
      {
        isShown.value = false;
        emit('changeRegion');
        document.body.removeEventListener('click', handleClickOutside);
      }
    };

    const onEnterClick = () =>
    {
      isShown.value = false;
      if(filteredList.value.length === 1)
      {
        emit('update:modelValue', filteredList.value[0].name);
        emit('changeRegion');
        selectedValue.value = '';
        return;
      }
      error.value = !(props.options.filter((region) => region.name.toLowerCase() === props.modelValue.toLowerCase()).length > 0);
      setTimeout(() =>
      {
        error.value = false;
      }, 2000);
      if(!error)
      {
        emit('update:modelValue', selectedValue.value);
      }
      return;
    };

    return {
      isShown,
      filteredList,
      error,
      selectedValue,
      onInput,
      onOptionClick,
      toggleList,
      onFocusOut,
      onEnterClick,
    };
  },
  template: `
  <div class="input-help" v-if="!locked" :class="{'input--error': error, 'input-help--open': isShown}" @click="toggleList">
    <label class="input-help__label" for="region">Регион</label>
    <input
      class="input-help__input"
      name="Region[]"
      id="region"
      type="text"
      autocomplete="off"
      :placeholder="label"
      @input="onInput"
      @blur="onFocusOut"
      :value="modelValue"
      @keyup.enter="onEnterClick"
    />
    <ul v-show="isShown > 0 && filteredList.length > 0" class="input-help__list">
      <li v-for="option in filteredList" class="input-help__item" @click="(event) => onOptionClick(event, option)" :key="option.id">{{option.name}}</li>
    </ul>
  </div>
  <div class="input-help" v-else>
    <label class="input-help__label" for="region">Регион</label>
    <input
      class="input-help__input"
      name="Region[]"
      id="region"
      type="text"
      autocomplete="off"
      :placeholder="label"
      :value="filteredList[0].name"
      readonly
    />
  </div>
  `,
};
