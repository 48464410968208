import ProgramTag from './UI/ProgramTag';
import moment from 'moment';

const { computed } = Vue;
const { useStore } = Vuex;

export default {
  emits: ['updateRegionModal', 'updateFeedbackModal', 'updateDateModal'],
  props: ['data'],
  setup(props, { emit }) {
    const store: {state: StoreState, dispatch: any, commit: any, getters: any} = useStore();
    // const storeCurrentDate = computed(() => store.state.currentDate);
    const specialCharacters = {
      '&quot;': '"',
    };
    const activeDates = props.data.Ar_events.filter((event) => event.IsActive === 'true');
    const dates = computed(() =>
      props.data.Ar_events
          .filter((event) => {
            const registrationEndDate = moment(event.RegistrationEnd);
            const educationStart = moment(event.DateStart);
            const todayDate = moment();
            // const choosedRegistrationStart = moment(storeCurrentDate.value);
            // return todayDate <= registrationEndDate && choosedRegistrationStart <= educationStart;
            return todayDate <= registrationEndDate;
          })
          .sort((a, b) => {
            const startDateA = moment(a.RegistrationStart);
            const startDateB = moment(b.RegistrationStart);
            return startDateA - startDateB;
          }));

    const getRecruitmentText = (dates) => {
      if (dates.length) {
        return `Набор: ${moment(dates[0].RegistrationStart).format('DD.MM.YYYY')} - ${moment(dates[0].RegistrationEnd).format('DD.MM.YYYY')}`;
      }
      return 'Набор временно недоступен';
    };

    const cleanTags = (text) => text.replace(/<\/?[^>]+(>|$)/g, '').replaceAll('&quot;', specialCharacters['&quot;']);

    const onDateClick = () => {
      emit('updateDateModal', dates.value);
    };

    const onFeedbackButtonClick = () => {
      ym(84290710, 'reachGoal', 'ost_zayavku');
      ym(84290710, 'reachGoal', 'click_on_application');
      emit('updateFeedbackModal', props.data);
    };

    const onRegionButtonClick = () => {
      emit('updateRegionModal', props.data.Ar_Regions);
    };

    // const urlDate = computed(() => moment(storeCurrentDate.value).format('DD-MM-YYYY'));

    return {
      activeDates,
      // storeCurrentDate,
      dates,
      // urlDate,
      onRegionButtonClick,
      getRecruitmentText,
      onFeedbackButtonClick,
      cleanTags,
      onDateClick,
    };
  },
  components: {
    ProgramTag,
  },
  template: `
  <li class="program-card" :class="{'program-card--disabled': data.Ar_Regions.length === 0}">
    <h2 class="program-card__heading">{{data.Title}}</h2>
    <p class="program-card__description">
      {{cleanTags(data.ShortDescription)}}
    </p>
    <div class="program-card__tags">
      <p class="program-card__label">Профессия</p>
      <p class="program-card__tag">{{data.Profession}}</p>
    </div>
    <div class="program-card__info">
      <ul class="program-card__list">
        <li class="program-card__item">
          <p class="program-card__data program-card__data--hours">
            {{ data.Hours }} ч
          </p>
        </li>
        <li class="program-card__item">
          <p v-if="data.Ar_Regions.length > 1" class="program-card__data program-card__data--region">
            <div @click="onRegionButtonClick">
              <p class="program-card__tooltip-label">Регионы</p>
            </div>
          </p>
          <p v-else-if="data.Ar_Regions.length === 1" class="program-card__data program-card__data--region">
            {{ data.Ar_Regions[0].Region }}
          </p>
        </li>
        <li class="program-card__item">
          <p class="program-card__data program-card__data--type">
            {{ data.EduForm }}
          </p>
        </li>
      </ul>
    </div>
    <div class="program-card__buttons">
      <button v-if="false" class="program-card__button program-card__button--primary" type="button" @click="onFeedbackButtonClick"
      >Оставить заявку</button>
      <button v-if="(activeDates.length !== 0) || (data.Ar_Regions.length !== 0)" class="program-card__button program-card__button--primary" type="button" @click="onFeedbackButtonClick">Оставить заявку</button>
      <a
        class="program-card__button program-card__button--link"
        :class="{'program-card__button--disabled': (activeDates.length === 0) || (data.Ar_Regions.length === 0)}"
        :href="(activeDates.length !== 0) || (data.Ar_Regions.length > 0) ? '/catalog-dpo/program/entrance.php?uid=' + data.UID : ''"
        target="_blank"
        onclick="ym(84290710,'reachGoal','podrobnee');"
      >
        Подробнее
      </a>
    </div>
  </li>
  `,
};

// :href="activeDates.length !== 0 ? '/catalog-dpo/program/entrance.php?uid=' + data.UID + '&date=' + urlDate : ''"
// <button v-if="(activeDates.length !== 0) || (data.Ar_Regions.length !== 0)" class="program-card__button program-card__button--primary" type="button" @click="onFeedbackButtonClick">Оставить заявку</button>

// Даты наборов
// <p class="program-card__recruitment program-card__recruitment--underline" v-if="dates.length > 1" @click="onDateClick">Даты наборов</p>
// <p class="program-card__recruitment" v-else>{{getRecruitmentText(dates)}}</p>
