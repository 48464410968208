import AttentionIcon from '../Icons/AttentionIcon';
import Button from './Button';

const { useStore } = Vuex;
const { computed } = Vue;

export default {
  props: ['data'],
  emits: ['changeEmptyRegion'],
  setup(props, { emit })
  {
    const store = useStore();

    const isLockedRegion = computed(() => store.state.lockedRegion);

    const onChangeRegionClick = () =>
    {
      store.state.isLoadingLinkEmptyError = false;
      emit('changeEmptyRegion', props.data.programData.UID, props.data.feedbackRegionUID);
      store.state.isFeedbackModalShown = true;
    };

    const onChangeProgram = () =>
    {
      store.state.isLoadingLinkEmptyError = false;
      const scrollY = document.body.style.top;
      document.body.style.top = '';
      document.body.classList.remove('scroll-lock');
      window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
    };

    return {
      isLockedRegion,
      onChangeRegionClick,
      onChangeProgram,
    };
  },
  components: {
    AttentionIcon,
    Button,
  },
  template: `
  <div class="gl-modal gl-modal--error gl-modal--link-error">
    <div class="gl-modal__container">
      <AttentionIcon />
      <h2 class="gl-modal__heading">Мы выяснили, что в этом регионе нет такой программы. Выберите другой регион или измените программу</h2>
      <div class="gl-modal__buttons" v-if="!isLockedRegion">
        <Button class="gl-modal__button" typeButton="primary" @click="onChangeRegionClick">Изменить регион</Button>
        <Button class="gl-modal__button" typeButton="secondary" @click="onChangeProgram">Изменить программу</Button>
      </div>
      <div class="gl-modal__buttons" v-else>
        <Button class="gl-modal__button" typeButton="primary" @click="onChangeProgram">Изменить программу</Button>
      </div>
    </div>
  </div>
  `,
};
