const { ref, computed } = Vue;
const { useStore } = Vuex;

export default {
  props: ['programsPerPage'],
  setup(props)
  {
    const store = useStore();
    const MIN_PAGE = 4;
    const PAGE_LIMIT_START = 2;
    const MIN_PAGE_BUTTONS = 4;
    const page: {value: number} = computed(() => store.state.currentPage);
    const startPage: {value: number} = ref(1);
    const totalPages: {value: number} = computed(() => Math.ceil(store.state.foundPrograms / store.state.programsPerPage));
    const storeProgramsCount = computed(() => store.state.foundPrograms);
    const storePorgramsPerPage = computed(() => store.state.programsPerPage);

    const scrollToTop: () => void = () =>
    {
      const top = document.querySelector('#programm').offsetTop;
      window.scrollTo({
        top,
        left: 0,
        behavior: 'smooth',
      });
    };

    const onNextPageButtonClick = () =>
    {
      scrollToTop();
      store.state.currentPage++;
      store.dispatch('fetchNewPage', page.value);
    };

    const onPreviousPageButtonClick = () =>
    {
      scrollToTop();
      store.state.currentPage--;
      store.dispatch('fetchNewPage', page.value);
    };

    const onPageButtonClick: (pageNumber: number) => void = (pageNumber) =>
    {
      scrollToTop();
      page.value = pageNumber;
      store.dispatch('fetchNewPage', pageNumber);
    };

    const pageNumbers = computed(() =>
    {
      if(totalPages.value <= MIN_PAGE)
      {
        startPage.value = 1;
      }
      else if(page.value <= PAGE_LIMIT_START)
      {
        startPage.value = 1;
      }
      else if(page.value >= totalPages.value - PAGE_LIMIT_START)
      {
        startPage.value = totalPages.value - MIN_PAGE_BUTTONS + 1;
      }
      else
      {
        startPage.value = page.value - PAGE_LIMIT_START;
      }

      const endPage = Math.min(startPage.value + MIN_PAGE_BUTTONS - 1, totalPages.value);

      return Array.from({
        length: endPage - startPage.value + 1,
      }, (el, index) => startPage.value + index);
    });

    const counterPrograms = () =>
    {
      if((page.value * storePorgramsPerPage.value) > storeProgramsCount.value)
      {
        return storeProgramsCount.value;
      }
      else
      {
        return page.value * storePorgramsPerPage.value;
      }
    };

    return {
      page,
      totalPages,
      pageNumbers,
      storeProgramsCount,
      storePorgramsPerPage,
      onNextPageButtonClick,
      onPreviousPageButtonClick,
      onPageButtonClick,
      counterPrograms,
    };
  },
  template: `
    <div class="pagination">
      <div class="pagination__pages" v-if="totalPages > 1">
        <button
          v-if="page !== 1"
          class="pagination__button pagination__control pagination__control--prev"
          @click="onPreviousPageButtonClick"
        >
          <span>Назад</span>
        </button>
        <ol class="pagination__list">
          <li v-for="pageNumber in pageNumbers" :data-page="pageNumber">
            <button
              class="pagination__button"
              :class="{'pagination__button--current': pageNumber === page}"
              @click="onPageButtonClick(pageNumber)"
              :disabled="pageNumber === page ? true : false"
            >
            {{pageNumber}}
            </button>
          </li>
        </ol>
        <button
          class="pagination__button pagination__control pagination__control--next"
          v-if="page !== totalPages"
          @click="onNextPageButtonClick"
        >
          <span>дальше</span>
        </button>
      </div>
      <div>
        <p class="pagination__counter">{{counterPrograms()}} из {{storeProgramsCount}}</p>
      </div>
    </div>
  `,
};
