import moment from 'moment';

const { createStore } = Vuex;

const fetchWithTimeout = async (resource, options = {
}) => {
  const { timeout = 8000 } = options;
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  });
  clearTimeout(id);

  return response;
};

const store = createStore({
  state() {
    return {
      programs: [],
      totalPrograms: 0,
      foundPrograms: 0,
      formData: null,
      filters2: {
        regions: [],
        activities: [],
        educationType: [],
        educationLevel: [],
      },
      filters: {
        search: '',
        region: '',
        activity: [],
        educationType: [],
        educationLevel: '',
      },
      lockedActivity: '',
      lockedRegion: '',
      currentPage: 1,
      programsPerPage: 10,
      links: [],
      formStep: 1,
      isFeedbackModalShown: false,
      isError: false,
      isLoadingLinkError: false,
      isLoadingLinkEmptyError: false,
      currentDate: moment(),
      isNextYearModal: false,
      linkLoading: false,
      isSuccess: false,
    };
  },
  mutations: {
    updateData(state, data) {
      state.data = data;
    },
    updateFilters(state, data) {
      state.filters.activity = data.activity;
      state.filters.region = data.region;
      state.filters.educationType = data.educationType;
      state.filters.educationLevel = data.educationLevel;
      state.filters.search = data.search;
    },
    updatePrograms(state, data) {
      state.programs = data;
    },
    updateFilters2(state, data) {
      state.filters2.activities = data.Profile;
      state.filters2.educationType = data.EduForm;
      state.filters2.educationLevel = data.MinEduLevel;
    },
    updateFilters2Region(state, data) {
      state.filters2.regions = data;
    },
    updateFoundProgramsCount(state, data) {
      state.foundPrograms = data;
    },
    updateTotalProgramsCount(state, data) {
      state.totalPrograms = data;
    },
    updateProgramsCount(state, data) {
      state.programsPerPage = data;
      state.currentPage = 1;
      store.dispatch('fetchNewPrograms');
    },
    updateFormData(state, data) {
      state.formData = data;
    },
    updateCurrentPage(state, data) {
      state.currentPage = data;
    },
    updateLockedActivity(state, data) {
      state.filters.activities = data;
      state.lockedActivity = data;
    },
    updateLockedRegion(state, data) {
      state.lockedRegion = data;
    },
    updateLinks(state, data) {
      state.links = data;
    },
    resetFormStep(state) {
      state.formStep = 1;
    },
    toggleFeedbackForm(state) {
      state.isFeedbackModalShown = !state.isFeedbackModalShown;
    },
    toggleError(state) {
      state.isError = !state.isError;
    },
    updateErrorMessage(state, data) {
      state.errorMessage = data;
    },
    updateCurrentDate(state, data) {
      state.currentDate = data;
    },
    resetError(state) {
      state.isError = false;
      state.errorMessage = '';
    },
    toggleLoading(state) {
      state.isLoading = !state.isLoading;
    },
    toggleNextYearModal(state) {
      state.isNextYearModal = !state.isNextYearModal;
    },
    toggleSuccess(state) {
      state.isSuccess = !state.isSuccess;
    },
  },
  getters: {
    isControlShown(state) {
      let sum = 0;

      if (state.formData) {
        for(const value of state.formData) {
          if (value[1]) {
            sum += 1;
          }
        }
      }
      return sum > 0;
    },
    getLockedActivity(state) {
      return Object.keys(state.filters2.activities).
          filter((key) => key.includes(state.lockedActivity)).
          reduce((cur, key) =>
            Object.assign(cur, {
              [key]: state.filters2.activities[key],
            }), {
          });
    },
    getLockedRegion(state) {
      return Object.keys(state.filters2.regions).
          filter((key) => state.filters2.regions[key].includes(state.lockedRegion)).
          reduce((cur, key) =>
            Object.assign(cur, {
              [key]: state.filters2.regions[key],
            }), {
          });
    },
  },
  actions: {
    async fetchPrograms({ state, commit, getters }) {
      commit('toggleLoading');
      let url = `/sync/getTrud.php?page=${state.currentPage}&limit=${state.programsPerPage}`;

      if (state.lockedActivity) {
        url += `&Profile[]=${state.lockedActivity}`;
      }

      if (state.lockedRegion) {
        const lockedRegionKey = Object.keys(getters.getLockedRegion)[0];
        url += `&Region[]=${lockedRegionKey}`;
      }

      try {
        const response = await fetch(url);

        if (response.ok) {
          const data = await response.json();
          commit('updatePrograms', data.program_data);
          commit('updateFilters2', data.filter_data);
          commit('updateFilters2Region', data.filter_data.Region);
          commit('updateFoundProgramsCount', data.program_find);
          commit('updateTotalProgramsCount', data.program_total);
        } else {
          throw new Error('Network response was not ok.');
        }
      } catch (error) {
        console.log(error);
      } finally {
        commit('toggleLoading');
      }
    },
    async fetchNewPrograms({ state, commit, getters }) {
      let url = `/sync/getTrud.php?page=${state.currentPage}&limit=${state.programsPerPage}`;

      if (state.lockedActivity) {
        url += `&Profile[]=${state.lockedActivity}`;
      }

      if (state.lockedRegion) {
        const lockedRegionKey = Object.keys(getters.getLockedRegion)[0];
        url += `&Region[]=${lockedRegionKey}`;
      }

      try {
        commit('updateCurrentPage', 1);
        commit('toggleLoading');

        const response = await fetch(url, {
          method: 'post',
          body: state.formData,
        });

        if (response.ok) {
          const data = await response.json();
          commit('updatePrograms', data.program_data || []);
          commit('updateFoundProgramsCount', data.program_find || 0);
          commit('updateFilters2', data.filter_data);
        } else {
          throw new Error('Network response was not ok.');
        }
      } catch (error) {
        console.log(error);
      } finally {
        commit('toggleLoading');
      }
    },
    async fetchNewPage({ state, commit, getters }, data) {
      commit('toggleLoading');
      commit('updateCurrentPage', data);
      let url = `/sync/getTrud.php?page=${state.currentPage}&limit=${state.programsPerPage}`;

      if (state.lockedActivity) {
        url += `&Profile[]=${state.lockedActivity}`;
      }

      if (state.lockedRegion) {
        const lockedRegionKey = Object.keys(getters.getLockedRegion)[0];
        url += `&Region[]=${lockedRegionKey}`;
      }
      commit('updateCurrentPage', data);
      try {
        await fetch(url,
            {
              method: 'post',
              body: state.formData,
            })
            .then((response) => {
              if (response.ok) {
                return response.json();
              }
              throw new Error('Network response was not ok.');
            }
            ).then((response) => {
              commit('updatePrograms', response.program_data);
              commit('updateFoundProgramsCount', response.program_find);
            });
      } catch (error) {
        console.log(error);
      } finally {
        commit('toggleLoading');
      }
    },
    async fetchfeedbackLink({ state, commit }, data) {
      state.linkLoading = true;
      try {
        ym(84290710, 'reachGoal', 'zapros_ssilki');
        const response = await fetchWithTimeout(`/sync/rvr_entry.php?UID_KatalogDPO=${data.program}&UID_Region=${data.region}`, {
          timeout: 10000,
        });
        const program = await response.json();
        if (program.links) {
          commit('updateLinks', program.links[Object.keys(program.links)[0]]);
          state.linkLoading = false;
        } else {
          ym(84290710, 'reachGoal', 'zapros_net_ssilki');
          state.isFeedbackModalShown = false;
          state.linkLoading = false;
          state.isLoadingLinkEmptyError = true;
        }
      } catch (error) {
        ym(84290710, 'reachGoal', 'zapros_ssilki_oshibka');
        state.isFeedbackModalShown = false;
        state.linkLoading = false;
        state.isLoadingLinkError = true;
      }
    },
    async sendFeedbackForm({ state, commit, getters }, { data, level, region, program, link }) {
      const object = new Object();

      data.forEach((value, key) => object[key] = value);
      const json = JSON.stringify(object);
      const formData = new FormData();
      state.links = link;
      formData.append('data', json);
      formData.append('link', link);
      formData.append('level', level);
      formData.append('region', region);
      formData.append('program', program);
      ym(84290710, 'reachGoal', 'otpravit');
      state.formStep++;
      fetch('/sync/rvr_entry.php?action=save', {
        method: 'post',
        body: formData,
      });
    },
    async sendNextYearForm({ state, commit }, data) {
      commit('toggleLoading');
      try {
        const json = JSON.stringify(data);
        const formData = new FormData();
        formData.append('data', json);
        const response = await fetch('/sync/rvr_entry.php?action=zayavNextYear', {
          method: 'post',
          body: formData,
        });
        if (!response.ok) {
          throw new Error('Request failed');
        }
        commit('toggleSuccess');
      } catch (error) {
        commit('toggleError');
      } finally {
        commit('toggleLoading');
        store.commit('toggleNextYearModal');
      }
    },
  },
});

const queryParams = new URLSearchParams(location.search.slice(1));
const lockedActivity = queryParams.get('PROFILE');

if (lockedActivity) {
  store.commit('updateLockedActivity', lockedActivity);
}

export default store;
