import ErrorIcon from '../Icons/ErrorIcon';
import Button from './Button';

const { useStore } = Vuex;
const { computed } = Vue;

export default {
  props: ['data'],
  emits: ['changeRegion'],
  setup(props, { emit })
  {
    const store = useStore();

    const isLockedRegion = computed(() => store.state.lockedRegion);

    const onChangeRegionClick = () =>
    {
      store.state.isLoadingLinkError = false;
      store.state.isFeedbackModalShown = true;
      emit('changeRegion');
    };

    const onRepeatClick = () =>
    {
      store.state.isLoadingLinkError = false;
      store.state.isFeedbackModalShown = true;

      store.dispatch('fetchfeedbackLink', {
        program: props.data.programData.UID,
        region: props.data.feedbackRegionUID,
      });
    };

    return {
      onChangeRegionClick,
      onRepeatClick,
      isLockedRegion,
    };
  },
  components: {
    ErrorIcon,
    Button,
  },
  template: `
  <div class="gl-modal gl-modal--error gl-modal--link-error">
    <div class="gl-modal__container">
      <ErrorIcon />
      <h2 class="gl-modal__heading">Очень жаль, в системе что-то сломалось. Попробуйте еще раз</h2>
      <div class="gl-modal__buttons" v-if="!isLockedRegion">
        <Button class="gl-modal__button" typeButton="primary" @click="onRepeatClick">Повторить</Button>
        <Button class="gl-modal__button" typeButton="secondary" @click="onChangeRegionClick">Изменить регион</Button>
      </div>
      <div class="gl-modal__buttons gl-modal__buttons" v-else>
        <Button class="gl-modal__button" typeButton="primary" @click="onRepeatClick">Повторить</Button>
      </div>
    </div>
  </div>
  `,
};
