export default {
  props: ['data', 'type'],
  template: `
  <template v-if="type === 'form'">
    <p
      class="program__tag"
      :class="{'program__tag--remote': data === 'Заочная',
               'program__tag--part-time': data === 'Очно-заочная'}"
    >
      {{data}}
    </p>
  </template>
  <template v-else>
    <p
      class="program__tag program__tag--scarlet"
      :class="{'program__tag--scarlet' : data === 'Бакалавриат',
              'program__tag--specialist' : data === 'Специалитет',
              'program__tag--magistracy' : data === 'Магистратура'}"
    >
      {{data}}
    </p>
  </template>
  `
}
